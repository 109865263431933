import React, { useState } from 'react';
import logo from "../logo.svg"; // Ruta de tu archivo de imagen del logo
import { useStudent } from '../context/studentsContext';
import { useNavigate } from "react-router-dom";

export function StudentForm() {

  const { studenInformation, setStudentInformation, finalMessage, setFinalMessage } = useStudent();
 
  const handleChange = ({target: {name,value}}) =>{
    setStudentInformation({...studenInformation,[name]:value})
  };

  const navigator = useNavigate();

  const handleSubmit = (event) => {
    event.preventDefault();

//    console.log(studenInformation)
    navigator("/hellie-questions/homestudent")
  };

  return (
    <div className="min-h-screen flex items-center justify-center bg-purple-500">
      <div className="max-w-md p-6 bg-white rounded shadow-lg">

      <a href='./' >
            <div className="flex flex-col items-center justify-center mb-6">
                <img src={logo} alt="Logo" className="w-21 h-20 mx-auto" />
              <h1 className="text-3xl font-bold font-display  text-center">Hellie Questions</h1>
            </div>
      </a>


        <h2 className="text-2xl font-semibold text-gray-500 mb-4">¡Vamos a Empezar!.</h2>
        <form onSubmit={handleSubmit} className="space-y-6">

        <div>
            <label htmlFor="name" className="block text-gray-700">
              Nombre
            </label>
            <input
              type="text"
              id="name"
              name="name"
              onChange={handleChange}
              placeholder="Ingresa tu nombre"
              className="mt-1 px-4 py-2 w-full border border-gray-300 rounded-md focus:outline-none focus:ring-purple-500 focus:border-purple-500"
              required
            />
          </div>

          <div>
            <label htmlFor="email" className="block text-gray-700">
              Correo Electrónico
            </label>
            <input
              type="email"
              id="email"
              name="email"
              onChange={handleChange}
              placeholder="youremail@company.com"
              className="mt-1 px-4 py-2 w-full border border-gray-300 rounded-md focus:outline-none focus:ring-purple-500 focus:border-purple-500"
              required
            />
          </div>

          <div>
            <label htmlFor="identification" className="block text-gray-700">
            Número de identificación
            </label>
            <input
              type="text"
              id="identification"
              name="identification"
              onChange={handleChange}
              placeholder="Ingresa tu número de identificación"
              className="mt-1 px-4 py-2 w-full border border-gray-300 rounded-md focus:outline-none focus:ring-purple-500 focus:border-purple-500"
              required
            />
          </div>

          <div>
            <label htmlFor="courseId" className="block text-gray-700">
              ID del Curso
            </label>
            <input
              type="text"
              id="courseId"
              name="courseId"
              onChange={handleChange}
              placeholder="Proporcionado por el profesor"
              className="mt-1 px-4 py-2 w-full border border-gray-300 rounded-md focus:outline-none focus:ring-purple-500 focus:border-purple-500"
              required
            />
          </div>

          <button
            type="submit"
            className="block w-full py-2 px-4 text-white bg-purple-600 rounded-md hover:bg-purple-700 focus:outline-none focus:bg-purple-700"
          >
            Responder
          </button>
        </form>
      </div>
    </div>
  );
}
