import React from 'react';
import { useAuth } from "../../context/authContext";
import { ArrowLeftIcon } from "@heroicons/react/24/solid";
import { useNavigate } from "react-router-dom";
import { useStudent} from "../../context/studentsContext";

export function Navbar() {
  const navigator = useNavigate();
  const {studenInformation} =useStudent()

  const hadleExit= () =>{
    navigator("/hellie-questions")
  }
  const handleArrow  = () => {
    window.history.back();
  };

  return (
    <nav className="bg-purple-800 shadow-md border-b-4 border-gray-300">
      <div className="flex items-center justify-between px-4 py-2">
        <div className="flex items-center space-x-2"
        onClick={handleArrow}>
          <ArrowLeftIcon className="h-6 w-6 text-white" />
          {/* Logo (Agrega aquí tu logo o ícono) */}
        </div>
        <div>
          <button className="text-white font-bold"
          onClick={hadleExit}
          >Salir</button>
        </div>
      </div>
      <div className="px-4 py-2 bg-purple-600 text-white flex justify-between items-center">
      <div className="text-sm font-medium">{studenInformation.identification}</div>
        <div className="text-sm font-medium">{studenInformation.name}</div>
        <div className="text-sm font-medium">{new Date().toLocaleDateString()}</div>
      </div>
    </nav>
  );
};

