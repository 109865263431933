import React, { useState } from 'react';
import {useStudent} from '../../context/studentsContext';
import { addDoc,collection,serverTimestamp,where } from 'firebase/firestore';
import { db } from '../../firebase';
import { query, orderBy } from "firebase/firestore";
import {useCollection} from 'react-firebase-hooks/firestore';


export function EvaluationScreenMS() {

  const {received,addAnswer, studenInformation,setStudentInformation,finalMessage, setFinalMessage,sendMessage} = useStudent();

  const firstQuery = query(collection(db,`Course`,studenInformation.courseId,'Evaluations'));
  const [value, loading, error] = useCollection(firstQuery);
  const valueEvaluation = value?.docs.filter((doc) => doc.id === studenInformation.idEvaluation);


  const [selectedOption, setSelectedOption] = useState(null);

  
  const handleChange= ({target: {name,value}}) =>{
    setStudentInformation({...studenInformation,[name]:value})
  };

  const handelSendAnswer = async() =>{
 
    await addAnswer(studenInformation);
    await sendMessage(finalMessage,studenInformation)
    received();
  };

  const handleOptionClick = (option) => {
    setSelectedOption(option);
    setStudentInformation({...studenInformation,['multipleChoiceAnswer']:option});
  };

  const updateData=(evaluationName,image,statement,multipleChoice,openText)=>{
    setFinalMessage({...finalMessage, ['evaluationName']: evaluationName,
                                      ['image']: image,
                                      ['statement']: statement,
                                      ['multipleChoice']: multipleChoice,
                                      ['openText']: openText})
  }

  return (
    <div className="px-4 py-6">
  {loading && "Loading..."}

  {/* Cargar pregunta */}









  {(valueEvaluation)?(
    <div>
    {valueEvaluation.map((doc) => (
    <div key={doc.id}>
      <h2 className="text-2xl font-bold mb-4" onClick={handleOptionClick}>
        {doc.data().name}
      </h2>

      {/* Mostrar el enunciado */}
      {doc.data().visible === 'yes' && (
        <div>
          {doc.data().image !== '' && (
            <div>
              <img
                src={doc.data().image}
                alt="Imagen de la evaluación"
                className="w-full mb-4"
              />
            </div>
          )}
          <p className="text-gray-800">{doc.data().statement}</p>
        </div>
      )}

      {/* Selección Multiple */}
      {parseInt(doc.data().multipleChoice, 10) !== 0 && (
        <div>
          <h3 className="text-lg font-bold my-2">Elije tu respuesta:</h3>
          <div className="flex justify-center space-x-2">
            {[...Array(parseInt(doc.data().multipleChoice))].map((_, index) => String.fromCharCode(65 + index)).map((option) => (
              <button
                key={option}
                className={`flex items-center justify-center w-10 h-10 rounded-full border-2 ${
                  selectedOption === `option${option}` ? 'border-purple-500 bg-purple-500 text-white' : 'border-gray-300 bg-white text-gray-700'
                } focus:outline-none`}
                onClick={() => {handleOptionClick(`option${option}`);
                                updateData(doc.data().name,doc.data().image,doc.data().statement,doc.data().multipleChoice,doc.data().openText);
              }}
              >
                {option}
              </button>
            ))}
          </div>
        </div>
      )}

      {/* Respuesta Escrita */}
      {doc.data().openText === 'yes' && (
        <div>
          <label htmlFor="explanation" className="block text-lg font-bold my-2">
            Respuesta Escrita:
          </label>
          <textarea
            id="textAnswer"
            name="textAnswer"
            rows="4"
            className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-purple-500 focus:border-purple-500"
            placeholder="Escribe una explicación..."
            onChange={(e)=>{handleChange(e)
              updateData(doc.data().name,doc.data().image,doc.data().statement,doc.data().multipleChoice,doc.data().openText)
            }}
          ></textarea>
        </div>
      )}

      <div className="bg-yellow-100 border-l-4 border-yellow-500 text-yellow-700 p-4 my-4">
        <p><strong>Importante:</strong> Tu respuesta solo será válida al presionar 'Enviar'.</p>
        <p>No habrá oportunidad de corregirla después de enviarla.</p>
        <p>¡Revisa cuidadosamente antes de hacer clic en 'Enviar'!</p>
      </div>


      {doc.data() && (
      <div>
        <button 
          className="block w-full py-3 px-4 bg-purple-500 text-white rounded-md shadow-md hover:bg-purple-600 focus:outline-none transition-colors duration-300 mt-4"
          onClick={() => {handelSendAnswer(doc.data())
                   //       console.log(finalMessage)
                        }}
        >
          Enviar
        </button> 
      </div>
      )}



    </div>
  ))}
  </div>):(
  <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative">
  <h1  className="font-bold mb-2">¡Hola!</h1>
  <p>Parece que presentamos un problema.</p>
  <p>¡Gracias por tu comprensión y paciencia!</p>
  </div>
)}
</div>

  );
}
