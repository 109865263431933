import React from 'react';
import { useProfessor } from '../../context/professorContext';
import { useAuth} from "../../context/authContext";
import { serverTimestamp } from 'firebase/firestore';

export function Activation() {

  const { user} = useAuth();
  const {activation,courseList, evaluationInfo,setEvaluation,addEvaluation, courseInformation} =useProfessor();


  const handelActivate = async () =>{
    setEvaluation({...evaluationInfo,['playTime']:serverTimestamp()})
    await addEvaluation(evaluationInfo,user,courseInformation)
//    console.log(evaluationInfo)
    courseList();

  }
  

  return (
    <div className="px-4 py-6">
      <h2 className="text-2xl font-bold mb-4">¡Evaluación completada!</h2>
      <p className="mb-4">La evaluación {evaluationInfo.name} está lista</p>
      <p className="text-gray-800">¿Desea activarla?</p>

      <button
        className="block w-full py-3 px-4 bg-purple-500 text-white rounded-md shadow-md hover:bg-purple-600 focus:outline-none transition-colors duration-300 mt-4"
        onClick={() => handelActivate()}>
        Activar
      </button>
    </div>
  );
};
