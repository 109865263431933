import React from 'react';
import { useAuth } from '../../context/authContext';
import {HomeIcon } from "@heroicons/react/24/solid";
import { useNavigate } from "react-router-dom";
import { useProfessor } from '../../context/professorContext';

export function Navbar() {
    const {courseList} =useProfessor();

    const { user, logout } = useAuth();
    
    const navigator = useNavigate();

    const handleLogout = async () => {
      try {
        await logout()
      } catch (error) {
        console.log(error.message);
      }
    }
  

    return (
      <nav className="bg-purple-800 shadow-md border-b-4 border-gray-300">
        <div className="flex items-center justify-between px-4 py-2">
          <div className="flex items-center space-x-2"
          onClick={()=>{ courseList() }}>
            {/* Home para atrás */}
            <HomeIcon className="h-6 w-6 text-white" />
            {/* Logo (Agrega aquí tu logo o ícono) */}
          </div>

          <div>
            {user && (
              <div className="flex items-center space-x-2">
                <p className="text-white text-purple-300"> {user.displayName || user.email}</p>
                <button
                  className="text-white font-bold"
                  onClick={handleLogout}
                >
                  Logout
                </button>
              </div>
            )}
          </div>
        </div>
      </nav>
    );
  };