import React, { useState } from 'react';
import { useProfessor } from '../../context/professorContext';
import { useAuth} from "../../context/authContext";


export function  CreateAnswer() {
    const [selectedOption, setSelectedOption] = useState(null);
    const {activation,evaluationInfo,setEvaluation} =useProfessor();



    const handleOptionClick = (option) => {
      setSelectedOption(option);
      setEvaluation({...evaluationInfo,['multipleChoiceAnswer']:option})
    };
  
    const handleChange = ({target: {name,value}}) =>{
      setEvaluation({...evaluationInfo,[name]:value})
    };

    const handelSend = async () =>{
      activation();
    }
    
    return (
        <div className="px-4 py-6">
          <h2 className="text-2xl font-bold mb-4">{evaluationInfo.name}</h2>
          <div className="bg-gray-100 rounded-md p-4 mb-4">
            {evaluationInfo.image!='' && 
            (<div>
              <img src={evaluationInfo.image} alt="Imagen de la evaluación" className="w-full mb-4" />
            </div>)
            }
           {evaluationInfo.statement  !='' && 
            (<div>
              <p className="text-gray-800">{evaluationInfo.statement}</p>
            </div>)
            }

          </div>
          <div>

          {parseInt(evaluationInfo.multipleChoice, 10) !== 0 && (
            <div>
            <h3 className="text-lg font-bold mb-2">Elije la respuesta correcta:</h3>
            <div className="flex  justify-center">
            {/* Opciones de selección múltiple */}
            {[...Array(parseInt(evaluationInfo.multipleChoice))].map((_, index) => String.fromCharCode(65 + index)).map((option) => (
              <button
                key={option}
                className={`flex mx-2 items-center justify-center w-10 h-10 rounded-full border-2 ${
                  selectedOption === `option${option}` ? 'border-purple-500 bg-purple-500 text-white' : 'border-gray-300 bg-white text-gray-700'
                } focus:outline-none`}
                onClick={() => handleOptionClick(`option${option}`)}
              >
                {option}
              </button>
            ))}
          </div>

            </div>)}         
        </div>
          <div>
            <label htmlFor="explanation" className="block text-lg font-bold mb-2">Explicación:</label>
            <textarea
              id="textAnswer"
              name="textAnswer"
              rows="4"
              className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-purple-500 focus:border-purple-500"
              placeholder="Escribe una explicación..."
              onChange={handleChange}
            ></textarea>
          </div>
          <button
            className="block w-full py-3 px-4 bg-purple-500 text-white rounded-md shadow-md hover:bg-purple-600 focus:outline-none transition-colors duration-300 mt-4"
            onClick={() => handelSend()}>
            Enviar
          </button>
        </div>
      );
};

