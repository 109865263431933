import {Navbar} from "./students/navbar";
import {EvaluationsList} from "./students/Evaluations";
import {EvaluationScreenMS} from "./students/EvaluationScreenMS";
import {Received} from "./students/Received";
import { useStudent } from "../context/studentsContext";
import { CurseStudentScreem } from "./students/CurseStudentScreem";

export function HomeStudent() {
  const {componente} =useStudent()

  return (
    <div>
      <Navbar />
      <div className="w-70 sm:w-1/2 mx-auto h-40">
      {componente === 'curseStudentScreem' && <CurseStudentScreem />} 
      {componente === 'Evaluations' && <EvaluationsList />}
      {componente === 'EvaluationsScreemMS' && <EvaluationScreenMS />}
      {componente === 'Received' && <Received />}

      </div>
    </div>
  );
}
  