import React, { useState } from 'react';

import { CourseList } from "./professor/CourseMenu";
import { Navbar } from "./professor/navbar";
import {CourseOptions} from "./professor/CourseOptions";
import {CreateCourse} from "./professor/CreateCourse";
import {CreateEvaluation} from "./professor/CreateEvaluation";
import {CreateAnswer} from "./professor/CreateAnswer";
import {Activation} from "./professor/Activation";
import { useProfessor } from '../context/professorContext';

export function Home() {

  const {componente} =useProfessor();


  return (
    <div>
      <Navbar />
      {componente === 'CourseList' && <CourseList />}

      <div className="w-70 sm:w-1/2 mx-auto h-40">
      {componente === 'CreateCourse' && <CreateCourse />}
      {componente === 'CourseOptions' && <CourseOptions />}
      {componente === 'CreateEvaluation' && <CreateEvaluation />}
      {componente === 'CreateAnswer' && <CreateAnswer />}
      {componente === 'Activation' && <Activation />}
      </div>
    </div>
  );
}
  
