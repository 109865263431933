import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import { Home } from './components/Home';
import { Alert } from './components/Alert';
import {AuthProvider} from './context/authContext'
import { Register } from './components/Register';
import { Login } from './components/Login';
import { ProtectedRote } from './components/ProtectedRoute';
import { UserTypeSelection } from './components/UserTypeSelect';
import { StudentForm } from './components/StudentForm';
import { ResetPassword } from './components/ResetPassword';
import { HomeStudent } from './components/Home_student';
import { ProfessorProvaider } from './context/professorContext';
import { StudentProvaider } from './context/studentsContext';
import {ProtectedRouteStudent} from './components/ProtectedRouteStudent';
import { Landing } from './landing';
import {SendMessagePage} from './messages';

const router = createBrowserRouter([
  {
    path: "/hellie-questions/home",
    element: <ProtectedRote> <Home /> </ProtectedRote>,
  },
  {
    path:"/hellie-questions/register",
    element: <Register/>
  },
  {
    path:"/hellie-questions/login",
    element: <Login />
  },
  {
    path:"/",
    element: <Landing />
  },
  {
    path:"/hellie-questions/studentform",
    element: <StudentForm/>
  },
  {
    path:"/hellie-questions/resetpassword",
    element: <ResetPassword/>
  },
  {
    path:"/hellie-questions/homestudent",
    element:<ProtectedRouteStudent> <HomeStudent/> </ProtectedRouteStudent>,
    
  },
  {
    path:"/hellie-questions",
    element: <UserTypeSelection/>,
    
  },
  {
    path:"/messages",
    element:<SendMessagePage/>,
  }



]);

ReactDOM.createRoot(document.getElementById("root")).render(
<React.StrictMode>
    <AuthProvider>
    <ProfessorProvaider>
    <StudentProvaider>
    <RouterProvider router={router} />
    </StudentProvaider>
    </ProfessorProvaider>
    </AuthProvider>
</React.StrictMode>
) ;



// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
