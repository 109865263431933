import React, { useState, useRef } from 'react';
import { storage } from '../../firebase';
import { ref, uploadString, getDownloadURL } from 'firebase/storage';
import { useProfessor } from '../../context/professorContext';

export const CameraCapture = () => {
  const [cameraVisible, setCameraVisible] = useState(false);
  const [capturedImage, setCapturedImage] = useState(null);
  const [uploadStatus, setUploadStatus] = useState(null);
  const [uploadMessage, setUploadMessage] = useState(null);
  const { evaluationInfo, setEvaluation } = useProfessor();
  const videoRef = useRef(null);
  const [facingMode, setFacingMode] = useState('environment'); // 'user' for front camera, 'environment' for rear camera
  const [imageStatus, setImageStatus] = useState('no-guardado');
  const [imageTone, setImageTone] = useState('normal');


  const openCamera = () => {
    setCameraVisible(true);
    navigator.mediaDevices
      .getUserMedia({ video: { facingMode: facingMode }, audio: false })
      .then((stream) => {
        videoRef.current.srcObject = stream;
        videoRef.current.play();
      })
      .catch((error) => {
        console.error('Error accessing camera:', error);
      });
  };

  const takePhoto = () => {
    const canvas = document.createElement('canvas');
    canvas.width = videoRef.current.videoWidth;
    canvas.height = videoRef.current.videoHeight;
    canvas.getContext('2d').drawImage(videoRef.current, 0, 0, canvas.width, canvas.height);
    const dataURL = canvas.toDataURL();
    setCapturedImage(dataURL);
    closeCamera();
  };

  const uploadImageToFirebase = async () => {
    if (capturedImage) {
      setImageTone('gris');
      setUploadStatus('subiendo');
      setImageStatus('enviando');
      const storageRef = ref(storage, `${Date.now()}.jpg`);

      try {
        // Upload the image to Firebase Storage as a base64 string
        await uploadString(storageRef, capturedImage, 'data_url');

        // Get the download URL for the uploaded image
        const downloadURL = await getDownloadURL(storageRef);

        // You can use the downloadURL as needed, e.g., store it in the database, display the image, etc.
       // console.log('Image uploaded successfully:', downloadURL);
        setEvaluation({ ...evaluationInfo, ['image']: downloadURL });

        // Cambiar el estado de subida a "éxito" y mostrar mensaje
        setImageTone('normal');
        setUploadStatus('éxito');
        setUploadMessage('¡La imagen se ha subido con éxito!');

        // Reiniciar el estado de subida después de 3 segundos
        setTimeout(() => {
          
          setUploadStatus(null);
          setUploadMessage(null)
        }, 3000);
      } catch (error) {
        // Cambiar el estado de subida a "error" y mostrar mensaje
        setImageStatus('error');

        setUploadStatus('error');
        setUploadMessage('¡Hubo un error al subir la imagen!');

        // Reiniciar el estado de subida después de 3 segundos
        setTimeout(() => {
          setUploadStatus(null);
          setUploadMessage(null)
        }, 3000);
        console.error('Error uploading image to Firebase:', error);
      }
    }
  };

  const closeCamera = () => {
    setCameraVisible(false);
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();

    reader.onloadend = () => {
      setCapturedImage(reader.result);
    };

    if (file) {
      reader.readAsDataURL(file);
    }
  };

  // Estilos condicionales para el botón de subida
  const uploadButtonStyle = {
    backgroundColor:
      imageStatus === 'enviando'
        ? 'blue'
        : imageStatus === 'enviado'
        ? 'green'
        : imageStatus === 'error'
        ? 'red'
        : uploadStatus === 'subiendo'
        ? 'transparent'
        : 'blue',
    color: 'white',
  };

  // Estilos condicionales para la imagen
  const imageStyle = {
    filter: `grayscale(${imageTone === 'gris' ? 100 : 0}%)`,
    border: imageTone === 'rojo' ? '2px solid red' : 'none',
  };

  return (
    <div className="px-4 space-y-4">
      {!cameraVisible && (
        <div className="flex space-x-4 ">
          <button
            className="flex-1 py-3 px-4 bg-purple-500 text-white rounded-md shadow-md hover:bg-purple-600 focus:outline-none transition-colors duration-300"
            onClick={openCamera}
          >
            Tomar foto
          </button>


          <label 
            className="flex-1 py-3 px-4 bg-blue-500 text-white rounded-md shadow-md hover:bg-blue-600 focus:outline-none transition-colors duration-300 cursor-pointer flex items-center justify-center"
          >
            <span className='text-center'>Cargar una imagen</span>
            <input
              type="file"
              accept="image/*"
              onChange={handleFileChange}
              className="hidden"
            />
          </label>



        </div>
      )}

      {cameraVisible && (
        <div>
          <video className="w-full" ref={videoRef}></video>
          <div className="flex justify-center mt-4 space-x-4">
            <button
              className="w-24 py-3 bg-purple-500 text-white rounded-md shadow-md hover:bg-purple-600 focus:outline-none transition-colors duration-300"
              onClick={takePhoto}
            >
              Tomar foto
            </button>
            <button
              className="w-24 py-3 bg-red-500 text-white rounded-md shadow-md hover:bg-red-600 focus:outline-none transition-colors duration-300"
              onClick={closeCamera}
            >
              Cerrar
            </button>
          </div>
        </div>
      )}

      {capturedImage && (
        <div>
          <img
            src={capturedImage}
            alt="Captured"
            className="w-full mb-4"
            style={imageStyle} // Aplica el estilo condicional a la imagen
          />
          {uploadMessage && (
            <div
              className={`bg-${uploadStatus === 'éxito' ? 'green' : 'red'}-100 border border-${uploadStatus === 'éxito' ? 'green' : 'red'}-400 text-${uploadStatus === 'éxito' ? 'green' : 'red'}-700 px-4 py-3 rounded`}
              style={{
                position: 'absolute',
                top: '70%', // Center the message vertically
                left: '50%', // Center the message horizontally
                transform: 'translate(-50%, -50%)', // Center the message both horizontally and vertically
                backgroundColor: uploadStatus === 'éxito' ? 'rgba(0, 128, 0, 0.5)' : 'rgba(128, 0, 0, 0.5)', // Green for success, red for failure
              }}
            >
              <p className="font-bold mb-2">{uploadMessage}</p>
            </div>
          )}


          <div className="flex justify-center mt-4 space-x-4">
            <button
              className="w-24 py-3 bg-blue-500 text-white rounded-md shadow-md hover:bg-blue-600 focus:outline-none transition-colors duration-300"
              style={uploadButtonStyle}
              onClick={uploadImageToFirebase}
            >
              {uploadStatus === 'subiendo' ? 'Subiendo...' : 'Enviar foto'}
            </button>

            </div>

        </div>
      )}
    </div>
  );
};
