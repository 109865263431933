import React, { useState } from "react";
import { addDoc,collection,serverTimestamp,where } from 'firebase/firestore';
import { db } from './firebase';


export function SendMessagePage (){
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        message: '',
      });
    
      const [sending, setSending] = useState(false); // State to track if the form is being sent
      const [sent, setSent] = useState(false); // State to track if the form was successfully sent
      const [error, setError] = useState(false); // State to track if there was an error while sending the form
    
      const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
      };
    
      // Message information
      const sendMessage = (formData) => {
        if (formData.message !== '') {
          setSending(true);
          const messageAnswerRef = collection(db, 'mail');
          addDoc(messageAnswerRef, {
            to: 'johannes.talero@gmail.com',
            message: {
              subject: `Nuevo mensaje en Assitant Hellie de  ${formData.name}`,
              html:`
              <head>
              <meta charset="UTF-8">
              <meta http-equiv="X-UA-Compatible" content="IE=edge">
              <meta name="viewport" content="width=device-width, initial-scale=1.0">
              <title>Hellie Questions</title>
              <style>
              body {
                font-family: Arial, sans-serif;
                line-height: 1.6;
                background-color: #6C63FF;
                padding: 20px;
                color: #808080;
              }
              .message-container {
                max-width: 600px;
                margin: 0 auto;
                padding: 20px;
                background-color: #fff;
                border-radius: 8px;
                box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
              }
              h1 {
                text-align: center;
                font-size: 24px;
                color: #6C63FF;
                margin-bottom: 20px;
              }
              p {
                margin-bottom: 10px;
              }
              </style>
            </head>
            <body>

              <div className="message-container">
              <h1>Hellie Questions</h1>
              <p>Estimado Johannes,</p>
              <p>Te escribo para comentarte que hemos recibido un mensaje de contacto.</p>
              
              <h2>Información del Contacto:</h2>
              <ul>
                <li>Nombre: ${formData.name} </li>
                <li>Email:  ${formData.email}</li>
              </ul>
        
              <h2>Mensaje:</h2>
              <p>${formData.message}</p>
        
            </div>
            </body>
            `
            },
          })
            .then((response) => {
//              console.log(response);
              setSending(false);
              setSent(true);
            })
            .catch((error) => {
//              console.log(error.message);
              setSending(false);
              setError(true);
            });
        }
      };
    
      const handleSubmit = (e) => {
        e.preventDefault();
        sendMessage(formData);
//        console.log('Formulario enviado:', formData);
      };
    

  return (
    <div className="min-h-screen flex items-center justify-center bg-purple-800">
      <div className="max-w-md p-6 bg-white rounded shadow-lg">
        <h1 className="text-3xl font-bold font-display text-center mb-6">
          Contáctame
        </h1>

    {/* Conditional rendering for feedback */}
    <div className="text-center mb-4">
          {sending && (
            <div className="bg-purple-100 text-purple-600 text-xl p-3 rounded">
              Enviando...
            </div>
          )}
          {sent && (
            <div className="bg-green-100 text-green-600 text-xl p-3 rounded">
              Enviado correctamente.
            </div>
          )}
          {error && (
            <div className="bg-red-100 text-red-600 text-xl p-3 rounded">
              Hubo un error al enviar el mensaje.
            </div>
          )}
        </div>

        <form onSubmit={handleSubmit} className="space-y-6">
          <label htmlFor="name" className="block text-gray-700">
            Nombre
          </label>
          <input
            type="text"
            name="name"
            placeholder="Tu nombre"
            onChange={handleChange}
            className="mt-1 px-4 py-2 w-full border border-gray-300 rounded-md focus:outline-none focus:ring-purple-500 focus:border-purple-500"
            required
          />

          <label htmlFor="email" className="block text-gray-700">
            Email
          </label>
          <input
            type="email"
            name="email"
            placeholder="youremail@company.com"
            onChange={handleChange}
            className="mt-1 px-4 py-2 w-full border border-gray-300 rounded-md focus:outline-none focus:ring-purple-500 focus:border-purple-500"
            required
          />

          <label htmlFor="message" className="block text-gray-700">
            Mensaje
          </label>
          <textarea
            name="message"
            placeholder="Escribe tu mensaje aquí"
            onChange={handleChange}
            className="mt-1 px-4 py-2 w-full border border-gray-300 rounded-md focus:outline-none focus:ring-purple-500 focus:border-purple-500"
            required
            rows="4"
          />

          <button
            type="submit"
            className="mt-4 w-full py-2 px-4 text-white bg-purple-600 rounded-md hover:bg-purple-700 focus:outline-none focus:bg-purple-700"
          >
            Enviar mensaje
          </button>
        </form>
        <div className="mt-4 text-center">
          <a href="/" className="text-purple-400 hover:underline focus:outline-none">
            Volver al inicio
          </a>
        </div>
      </div>

    </div>
  );
};
