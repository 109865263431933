import { useState } from "react"
import { useAuth} from "../context/authContext"
import { useNavigate } from "react-router-dom";
import { Alert } from "./Alert";

import logo from "../logo.svg"; // Ruta de tu archivo de imagen del logo

export function ResetPassword() {
    const [user, setUser] =useState({
        email:"",
        password:"",
    });

    const {ResetPasswordFuncion} =useAuth();
    const navigator = useNavigate();
    const [error_s, setError] = useState();

    const handleChange = ({target: {name,value}}) =>{
      setUser({...user,[name]:value})
    };



    const handelResetPassword = async (e) =>{
      setError('');
      e.preventDefault();
      if (!user.email){ return setError('Ingresa un Email')}

      try{
        await ResetPasswordFuncion(user.email);
        setError('Se envio un mensaje a tu correo!'); 
      } catch {
        return setError('Se presento un Error ');
      }
    }
 
    return (
        <div className="min-h-screen flex items-center justify-center bg-purple-800">
          <div className="max-w-md p-6 bg-white rounded shadow-lg">

          <a href='./' >
            <div className="flex flex-col items-center justify-center mb-6">
                <img src={logo} alt="Logo" className="w-21 h-20 mx-auto" />
              <h1 className="text-3xl font-bold font-display  text-center">Hellie Questions</h1>
            </div>
          </a>
        
            <form onSubmit={handelResetPassword} className="space-y-6">
              <label htmlFor="email" className="block text-gray-700">
                Email
              </label>
              <input
                type="email"
                name="email"
                placeholder="youremail@company.com"
                className="mt-1 px-4 py-2 w-full border border-gray-300 rounded-md focus:outline-none focus:ring-purple-500 focus:border-purple-500"
                onChange={handleChange}
                required
              />
    
    
              <button
                type="submit"
                className="block w-full py-2 px-4 text-white bg-purple-500 rounded-md hover:bg-purple-600 focus:outline-none focus:bg-purple-600"
              >
                Enviar
              </button>
            </form>
    
            {error_s && (
              <div className="mt-4">
                <p className="text-red-500">{error_s}</p>
              </div>
            )}
    
            <div className="mt-4 text-center">
              <a
                href="/register"
                className="text-purple-400 hover:underline focus:outline-none"
              >
                Sign up
              </a>
              <span className="text-gray-600 mx-2">|</span>
              <a
                href="/login"
                className="text-purple-400 hover:underline focus:outline-none"
              >
                Login
              </a>
            </div>
          </div>
        </div>
      );
}