import { Navigate } from "react-router-dom";
import { useStudent } from "../context/studentsContext";

export function ProtectedRouteStudent({children}){

    const {studenInformation} =useStudent();

    if (studenInformation.name ===""){
        return <Navigate to="/" />;
    } else {
        return <div> {children} </div>;
    }

  
}