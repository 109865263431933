import React from 'react';
import { query, orderBy } from "firebase/firestore";  
import {useCollection} from 'react-firebase-hooks/firestore'
import { ProfessorProvaider,useProfessor } from '../../context/professorContext';
import { useAuth} from "../../context/authContext";
import { addDoc,collection,serverTimestamp,where } from 'firebase/firestore';
import { db } from '../../firebase';

export function CourseList(){

  const { user } = useAuth();
  const {newCourse,selectCourse,changeCourse} =useProfessor();
  
  const q_course = query(collection(db,`Course`),where("userId", "==", user.uid));
  const [value, loading, error] = useCollection(q_course);

  const handleNewCourse = () =>{
     newCourse();
  };

  const handleSelectCourse =(name,id) => {
    selectCourse();
    changeCourse(name,id)
  };

    

  return (
    <div className="px-4 py-6">
      <h2 className="text-2xl font-bold text-center mb-4">Cursos</h2>
      <div className="grid gap-4 grid-cols-1 sm:grid-cols-3">
  
        {loading && "Loading..."}
  
        {value?.docs.map((doc) => (
        <label
          key={doc.id}
          className="w-full h-24 bg-purple-500 text-white rounded-md shadow-md hover:bg-purple-600 focus:outline-none transition-colors duration-300"
          onClick={() => handleSelectCourse(doc.data().name, doc.id)}
          style={{ display: 'block', marginBottom: '10px', padding: '10px' }}
        >
          <strong className="text-lg font-bold">
            {doc.data().name} (#{doc.data().idCurso})
          </strong>
          <p className="text-base text-gray-700">{doc.data().topic}</p>

          <p className="text-sm italic">{doc.data().institution}</p>

        </label>
      ))}
      </div>
      <button className="fixed bottom-4 right-4 bg-purple-400 text-white hover:bg-purple-500 p-4 rounded-full shadow-lg"    
      onClick={handleNewCourse}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-6 w-6"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            d="M12 6v6m0 0v6m0-6h6m-6 0H6"
          />
        </svg>
      </button>
    </div>
  );
};

