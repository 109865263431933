import { createContext, useContext, useEffect, useState } from "react";
import {
    createUserWithEmailAndPassword, 
    signInWithEmailAndPassword,
    onAuthStateChanged,
    signOut, GoogleAuthProvider, signInWithPopup,sendPasswordResetEmail} from 'firebase/auth'
import {auth} from  "../firebase"



export const authContext = createContext();

export const useAuth =() => {
    const context =useContext(authContext);
    if (!context) throw new Error("There us nor ut provider");
    return context;
};


export function AuthProvider({children}){
    const [user, setUser] =useState(null);
    const [loading, setLoading]=useState(true);

    const signup = (email, password) => createUserWithEmailAndPassword(auth,email,password);
    const login = (email, password) => signInWithEmailAndPassword(auth,email,password);
    const ResetPasswordFuncion = (email) =>{ sendPasswordResetEmail(auth,email) };
    const loginWitGoogle = () => {
        const googleProvider= new GoogleAuthProvider();
        return signInWithPopup(auth,googleProvider);
    }


    const logout = () => signOut(auth);



    useEffect( () => {
        onAuthStateChanged(auth, (currentUser) =>{
        //    console.log({currentUser});
            setUser(currentUser);
            setLoading(false);
        })
    },[])

    return(
        <authContext.Provider value ={{signup, login, logout, loginWitGoogle, ResetPasswordFuncion, loading, user}}> {children} </authContext.Provider>
    );
}