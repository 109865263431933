import { createContext, useContext, useEffect, useState } from "react";
import { addDoc,collection,serverTimestamp,where } from 'firebase/firestore';
import { db } from '../firebase';
 
export const studentsContext = createContext();

export const useStudent =() => {
    const context =useContext(studentsContext);
    if (!context) throw new Error("There us not a provider");
    return context;
};


export function StudentProvaider({children}) {
    const [componente, setComponente] =useState('curseStudentScreem');
    
    // Obtenemos la fecha y hora actual
    const currentDate = new Date();

    // Función para agregar un cero inicial si el número tiene un solo dígito
    function addLeadingZero(number) {
    return number < 10 ? `0${number}` : number;
    }

    // Obtenemos los componentes de la fecha y hora actual
    const day = addLeadingZero(currentDate.getDate());
    const month = addLeadingZero(currentDate.getMonth() + 1); // Los meses empiezan en 0 (enero es 0)
    const year = currentDate.getFullYear();
    const hours = addLeadingZero(currentDate.getHours());
    const minutes = addLeadingZero(currentDate.getMinutes());

    // Formateamos la fecha y hora en el formato deseado (DD/MM/YYYY HH:mm)
    const formattedDateTime = `${day}/${month}/${year} ${hours}:${minutes}`;


    const [studenInformation, setStudentInformation] =useState({
        name:'',
        email:'',
        identification:'',
        courseId:'',
     // Answer   
        idEvaluation:'',
        multipleChoiceAnswer:"",
        textAnswer:"",
        startime:"",
        endTime:""
    });

    const [finalMessage, setFinalMessage] =useState({
        // Course
        professor:'',
        courseName:'',
        institution:'',
        // Evaluation
        evaluationName:'',
        statement:'',
        image:'',
        multipleChoice:'',
        openText:'',

    });



    //Course information
    const addAnswer =(studenInformation) => {
        if (studenInformation.name!==''){
            const studentAnswerRef = collection(db,'Course',studenInformation.courseId,'Evaluations',studenInformation.idEvaluation,'Answers')
            addDoc(studentAnswerRef,{
                name:studenInformation.name,
                email:studenInformation.email,
                identification:studenInformation.identification,
             // Answer   
                multipleChoiceAnswer:studenInformation.multipleChoiceAnswer,
                textAnswer:studenInformation.textAnswer,
                endTime:serverTimestamp(),
            }).then(Response =>{
            //    console.log(Response)
            }).catch(error =>{
            //    console.log(error.message)
                    })

       }
    };
    
    
    //Message information
    const sendMessage =(finalMessage,studenInformation) => {
    //    console.log(finalMessage)
    //    console.log(studenInformation)
        if (studenInformation.name!==''){
            const messageAnswerRef = collection(db,'mail')
            addDoc(messageAnswerRef,{
                to: studenInformation.email,
                message:{
                    subject: `Recibimos tu respuesta de ${finalMessage.evaluationName}`,
                    text: '',
                    html: `<!DOCTYPE html>
                    <html lang="es">
                    <head>
                        <meta charset="UTF-8">
                        <meta http-equiv="X-UA-Compatible" content="IE=edge">
                        <meta name="viewport" content="width=device-width, initial-scale=1.0">
                        <title>Hellie Questions</title>
                        <style>
                            body {
                                font-family: Arial, sans-serif;
                                line-height: 1.6;
                                background-color: #6C63FF;
                                padding: 20px;
                                color: #808080;
                            }
                            .message-container {
                                max-width: 600px;
                                margin: 0 auto;
                                padding: 20px;
                                background-color: #fff;
                                border-radius: 8px;
                                box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
                            }
                            h1 {
                                text-align: center;
                                font-size: 24px;
                                color: #6C63FF;
                                margin-bottom: 20px;
                            }
                            p {
                                margin-bottom: 10px;
                            }
                            .question {
                                font-weight: bold;
                                color: #6C63FF;
                            }
                            .answer {
                                color: #6C63FF;
                                font-weight: bold;
                            }
                            .next-steps {
                                font-style: italic;
                            }
                            .thanks {
                                text-align: center;
                                margin-top: 20px;
                                font-size: 18px;
                                font-weight: bold;
                            }
                            .image-container {
                                text-align: center;
                                margin-top: 20px;
                            }
                            img {
                                max-width: 100%;
                                border-radius: 8px;
                                box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
                            }
                        </style>
                    </head>
                    <body>
                        <div class="message-container">
                            <h1>Hellie Questions</h1>
                            <p>Estimado ${studenInformation.name},</p>
                            <p>Te escribo para confirmar que hemos recibido tu respuesta de la evaluación del ${formattedDateTime}.</p>
                            
                            <!-- Información del curso y la evaluación -->
                            <h2>Información del Curso:</h2>
                            <ul>
                                <li>Profesor: ${finalMessage.professor}</li>
                                <li>Nombre del Curso: ${finalMessage.courseName}</li>
                                <li>Institución: ${finalMessage.institution}</li>
                            </ul>
                            <h2>Información de la Evaluación:</h2>
                            <ul>
                                <li>Nombre de la Evaluación: ${finalMessage.evaluationName}</li>
                                <!-- Imagen si está disponible -->
                                ${finalMessage.image && finalMessage.image !== "" ? `
                                <div class="image-container">
                                    <img src="${finalMessage.image}" alt="Imagen de la evaluación" />
                                </div>
                                ` : ''}
                                
                                <li>Pregunta: ${finalMessage.statement}</li>
                            </ul>
                            
                            <!-- Respuesta múltiple -->
                            ${finalMessage.multipleChoice && studenInformation.multipleChoiceAnswer ? `
                            <h2>Respuesta Múltiple:</h2>
                            <p class="answer">Recibimos tu respuesta: ${studenInformation.multipleChoiceAnswer}.</p>
                            ` : ''}
                            
                            <!-- Respuesta de texto -->
                            ${finalMessage.openText && studenInformation.textAnswer ? `
                            <h2>Respuesta de Texto:</h2>
                            <p>${studenInformation.textAnswer}</p>
                            ` : ''}
                            
                            <p class="next-steps">
                                Próximos Pasos:<br>
                                El profesor evaluará cuidadosamente tu respuesta y proporcionará retroalimentación constructiva.
                            </p>
                            <p>Agradecemos tu compromiso y esfuerzo en el curso. Si tienes alguna otra pregunta o inquietud, no dudes en contactarnos.</p>
                            <p class="thanks">¡Muchas gracias!</p>
                        </div>
                    </body>
                    </html>
                    `,
                }
            }).then(Response =>{
            //    console.log(Response)
            }).catch(error =>{
//                console.log(error.message)
                    })

       }
    };




    const curseStudentScreem= () => {
        setComponente('curseStudentScreem');
    } 
    const evaluationsScreemMS= () => {
        setComponente('EvaluationsScreemMS');
    }
    const received= () => {
        setComponente('Received');
    } 
    const evaluations= () => {
        setComponente('Evaluations');
    } 



    
    return <studentsContext.Provider value ={{componente, evaluationsScreemMS,received,evaluations,curseStudentScreem,
                                             studenInformation, setStudentInformation, addAnswer,
                                             finalMessage, setFinalMessage, sendMessage}}> {children} </studentsContext.Provider>
};