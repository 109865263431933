import React, { useState } from 'react';
import { useProfessor } from '../../context/professorContext';
import {useCollection, useCollectionData} from 'react-firebase-hooks/firestore'
import { addDoc,collection,where } from 'firebase/firestore';
import { db } from '../../firebase';
import { query, orderBy } from "firebase/firestore";  



export function CreateCourse(){
  const {courseList,addCourse} =useProfessor();
  const [courseInfo, setCurse] =useState({
    name:"",
    topic:"",
    institution:"",
    idCurso:"",
  });

  const qIdC = query(collection(db,`IdCouseUnicos`));
  const [docs, loading, error] = useCollectionData(qIdC);

  const handleChange = ({target: {name,value}}) =>{
    setCurse({...courseInfo,[name]:value});
};

  const handlCreateCourse =async (e) => {
    const filteredDocs = docs.filter((doc) => doc.id == courseInfo.idCurso);
    if (!filteredDocs[0]){
      await addCourse(courseInfo.name, courseInfo.topic, courseInfo.institution,courseInfo.idCurso);
      courseList()}
    else{
      window.alert(`¡Este ID:${courseInfo.idCurso} ya está seleccionado!`);
    }
  };

  
  return (
    <div className="px-4 py-6">
      <h1 class="text-2xl font-bold text-center mb-4">Crear nuevo curso</h1>


      <form className='mx-8' onSubmit={(e)=>{ e.preventDefault()}}>
      <div className="space-y-6 ">
        <div>
          <label htmlFor="name" className="block text-gray-700">Nombre del curso</label>
          <input
            type="text"
            id="name"
            name="name"
            className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-purple-500 focus:border-purple-500"
            placeholder="Ingrese el nombre del curso"
            onChange={handleChange}
          />
        </div>
        <div>
          <label htmlFor="topic" className="block text-gray-700">Temática</label>
          <input
            type="text"
            id="topic"
            name="topic"
            className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-purple-500 focus:border-purple-500"
            placeholder="Ingrese la temática del curso"
            onChange={handleChange}
          />
        </div>
        <div>
          <label htmlFor="institution" className="block text-gray-700">Institución</label>
          <input
            type="text"
            id="institution"
            name="institution"
            className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-purple-500 focus:border-purple-500"
            placeholder="Ingrese la institución del curso"
            onChange={handleChange}
          />
        </div>
        <div>
          <label htmlFor="idCurso" className="block text-gray-700">Ingrese un código que identifique su curso</label>
          <input
            type="text"
            id="idCurso"
            name="idCurso"
            className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-purple-500 focus:border-purple-500"
            placeholder="Ingrese un código que identifique su curso"
            onChange={handleChange}
          />
        </div>
        <button
          className="block w-full py-3 px-4 bg-purple-500 text-white rounded-md shadow-md hover:bg-purple-600 focus:outline-none transition-colors duration-300"
          onClick={() => handlCreateCourse()}>
          Crear
        </button>
      </div>
      </form>
    </div>
  );
};
