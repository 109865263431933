import { createContext, useContext, useEffect, useState } from "react";
import { addDoc,collection,serverTimestamp,where } from 'firebase/firestore';
import { db } from '../firebase';
import { useAuth } from "./authContext";
 
export const professorContext = createContext();

export const useProfessor =() => {
    const context =useContext(professorContext);
    if (!context) throw new Error("There us not a provider");
    return context;
};

export function ProfessorProvaider({children}) {
    const {user} = useAuth();

    const [componente, setComponente] =useState('CourseList');

    const [courseInformation, setInformation] =useState({
        nameCurse:'',
        curseID:'',
        idCurso:'',

    });

    const [evaluationInfo, setEvaluation] =useState({
        name:"",
        statement:"",
        image:"",
        visible:"yes",
        multipleChoice:"0",
        openText:"yes",
        time:"5",
        multipleChoiceAnswer:"",
        textAnswer:"",
        playTime:"",  
      });

    //Course information
    const changeCourse= (nameCurse_val, curseID_val,idCurso_val) => {
        setInformation({
        nameCurse:nameCurse_val,
        curseID:curseID_val,
        idCurso:idCurso_val}
        )
    }

    const addEvaluation =(evaluation, user,course) => {
        if (evaluation.name!=''){
            const projectsCollRef = collection(db,'Course',course.curseID,'Evaluations')
            addDoc(projectsCollRef,{
                name:evaluation.name,
                statement:evaluation.statement,
                image:evaluation.image,
                visible:evaluation.visible,
                multipleChoice:evaluation.multipleChoice,
                openText:evaluation.openText,
                time:evaluation.time,
                multipleChoiceAnswer:evaluation.multipleChoiceAnswer,
                textAnswer:evaluation.textAnswer,
                playTime:evaluation.playTime,
                userId:user.uid,
                timestamp: serverTimestamp(),
            }).then(Response =>{
//                console.log(Response)
            }).catch(error =>{
                //console.log(error.message)
                    })

       }
    };
    
    const addCourse =(name,topic,institution,idCurso_val) => {
        if (name){
            const courseCollRef = collection(db,'Course')
            addDoc(courseCollRef,{
                name: name,
                topic:topic,
                institution:institution,
                userName:user.displayName,
                userId:user.uid,
                timestamp: serverTimestamp(),
                idCurso:idCurso_val
            }).then(Response =>{
            //    console.log(Response)
            }).catch(error =>{
            //    console.log(error.message)
                    })
            // Id 
            const idRef = collection(db,'IdCouseUnicos')
            addDoc(idRef,{
                id:idCurso_val
            })
       }
    };


    // Movement between pages
    const courseList= () => {
        setComponente('CourseList');
    }
    const newCourse= () => {
        setComponente('CreateCourse');
    } 
    const selectCourse= () => {
        setComponente('CourseOptions');
    } 
    const newEvaluation= () => {
        setComponente('CreateEvaluation');
        setEvaluation({
            name:"",
            statement:"",
            image:"",
            visible:"yes",
            multipleChoice:"0",
            openText:"yes",
            time:"5",
            multipleChoiceAnswer:"",
            textAnswer:"",
            playTime:"",});
    } 
    const getAnswer= () => {
        setComponente('CreateAnswer');
    //    console.log(evaluationInfo)
    } 
    const activation= () => {
        setComponente('Activation');
    } 

    return <professorContext.Provider value ={{componente,courseList,newCourse,selectCourse,newEvaluation,getAnswer,activation, 
                                              courseInformation,addCourse, changeCourse,
                                              evaluationInfo,setEvaluation,addEvaluation}}> {children} </professorContext.Provider>
};