import { useState } from "react"
import { useAuth} from "../context/authContext"
import { useNavigate } from "react-router-dom";
import { Alert } from "./Alert";
import logo from "../logo.svg"; // Ruta de tu archivo de imagen del logo

export function Register() {
    const [user, setUser] =useState({
        email:"",
        password:"",
    });

    const {signup} =useAuth();
    const navigator = useNavigate();
    const [error_s, setError] = useState();
    const {login, loginWitGoogle} =useAuth();


    const handleChange = ({target: {name,value}}) =>{
        setUser({...user,[name]:value})
    };

    const handleSubmit = async (e) => {
        setError('');
        e.preventDefault();
        try {
            await signup(user.email, user.password)
            navigator("/home")

        }
        catch (error) {
            if (error.code === 'auth/internal-error') {
                setError("Correo inválido");
              } else if (error.code === 'auth/invalid-email') {
                setError("El formato del correo electrónico es incorrecto");
              } else if (error.code === 'auth/email-already-in-use') {
                setError("El correo electrónico ya está en uso");
              } else if (error.code === 'auth/weak-password') {
                setError("La contraseña es débil, debe tener al menos 6 caracteres");
              } else {
                setError("Ocurrió un error al crear la cuenta");
              }
            }
    }

    const handelGoogleSignin = async () =>{
      try {
          await loginWitGoogle()
          navigator("/home")
  
      } catch (error)
      {
          setError(error.message);
      }
  
  }

    return (
      <div className="min-h-screen flex items-center justify-center bg-purple-800">  
        <div className="max-w-md p-6 bg-white rounded shadow-lg">

        <a href='./' >
            <div className="flex flex-col items-center justify-center mb-6">
                <img src={logo} alt="Logo" className="w-21 h-20 mx-auto" />
              <h1 className="text-3xl font-bold font-display  text-center">Hellie Questions</h1>
            </div>
        </a>

        <h1 class="text-2xl font-semibold mb-4">Registrarse como profesor</h1>

          <form onSubmit={handleSubmit}  className="space-y-6">
            <label htmlFor="email" className="block text-gray-700">Email</label>
            <input
              type="email"
              name="email"
              placeholder="youremail@company.com"
              onChange={handleChange}
              className="mt-1 px-4 py-2 w-full border border-gray-300 rounded-md focus:outline-none focus:ring-purple-500 focus:border-purple-500"
              required
              />
  
            <label htmlFor="password" className="block text-gray-700">Contraseña</label>
            <input
              type="password"
              name="password"
              placeholder="******"
              onChange={handleChange}
              className="mt-1 px-4 py-2 w-full border border-gray-300 rounded-md focus:outline-none focus:ring-purple-500 focus:border-purple-500"
              required
           />
  
            <button
              type="submit"
              className="mt-4 w-full py-2 px-4 text-white bg-purple-600 rounded-md hover:bg-purple-700 focus:outline-none focus:bg-purple-700"
            >
              Registrarse
            </button>
            <button
              onClick={handelGoogleSignin}
              className="mt-4 w-full py-2 px-4 text-white bg-purple-600 rounded-md hover:bg-purple-700 focus:outline-none focus:bg-purple-700"
            >
              Google
              </button>
          </form>
  
          {error_s && (
            <div className="mt-4">
              <p className="text-red-500">{error_s}</p>
            </div>
          )}
  
            <div className="mt-4 text-center">
              <a
                href="/Login"
                className="text-purple-400 hover:underline focus:outline-none"
              >
                Ya tengo cuenta
              </a>
              <span className="text-gray-600 mx-2">|</span>
              <a
                href="/resetpassword"
                className="text-purple-400 hover:underline focus:outline-none"
              >
                ¿Olvidaste tu contraseña?
              </a>
            </div>
  
        </div>
      </div>
    );
}