import React from 'react';
import logo from "../logo.svg"; // Ruta de tu archivo de imagen del logo


export function UserTypeSelection() {
    return (
      <div className="min-h-screen flex items-center justify-center bg-purple-800">
        <div className="max-w-md p-6 bg-white rounded shadow-lg">

        <a href='./' >
            <div className="flex flex-col items-center justify-center mb-6">
                <img src={logo} alt="Logo" className="w-21 h-20 mx-auto" />
              <h1 className="text-3xl font-bold font-display  text-center">Hellie Questions</h1>
            </div>
          </a>

          <div className="flex justify-center space-x-4">
            <button
             className="py-4 px-6 bg-purple-500 text-white rounded-md shadow hover:bg-purple-600 focus:outline-none"
             onClick={(e) => {
                e.preventDefault();
                window.location.href='/hellie-questions/studentform';
                }}            
            >
              Soy Estudiante
            </button>
            <button className="py-4 px-6 bg-purple-500 text-white rounded-md shadow hover:bg-purple-600 focus:outline-none"
                onClick={(e) => {
                e.preventDefault();
                window.location.href='/hellie-questions/login';
                }}            
            >
              Soy Profesor
            </button>
          </div>
        </div>
      </div>
    );
  }