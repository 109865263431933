import React from 'react';
import { useProfessor } from '../../context/professorContext';


export function CourseOptions() {
const {newEvaluation,courseInformation} =useProfessor();
const handleNewEvaluation= () =>{
  newEvaluation()
;}

  return (
    <div className="px-4 py-6">
    <h2 className="text-2xl font-bold text-center mb-4">{courseInformation.nameCurse}</h2>
    <div className="grid gap-4 grid-cols-1 sm:grid-cols-3">
      <button className="w-full h-24 bg-purple-500 text-white rounded-lg shadow-md hover:bg-purple-600 focus:outline-none transition-colors duration-300"
      onClick={() => handleNewEvaluation()}>
        Nueva evaluación
      </button>
      <button className="w-full h-24 bg-gray-400 text-white rounded-lg shadow-md hover:bg-gray-400 focus:outline-none transition-colors duration-300">
        Evaluaciones pasadas (Pronto)
      </button>
      <button className="w-full h-24 bg-gray-400 text-white rounded-lg shadow-md hover:bg-gray-400 focus:outline-none transition-colors duration-300">
        Insights (Pronto)
      </button>
    </div>
  </div>
  );
};
