import { useAuth } from "../context/authContext";
import { Navigate } from "react-router-dom";

export function ProtectedRote({children}){

    const {user, loading} =useAuth();

    if (loading){
        return <h1>Loading</h1>
    } else if (!user)  {
        return <Navigate to="/" />;
    }
    else {
        return <div> {children} </div>;
    }

  
}