import React, { useState } from 'react';
import {useStudent} from '../../context/studentsContext';
import { addDoc,collection,serverTimestamp,where } from 'firebase/firestore';
import { db } from '../../firebase';
import { query, orderBy } from "firebase/firestore";
import {useCollection} from 'react-firebase-hooks/firestore';
import { useNavigate } from "react-router-dom";




export function CurseStudentScreem  ()  {
  const navigator = useNavigate();
  const {evaluations, studenInformation,setStudentInformation,  finalMessage, setFinalMessage,} = useStudent();
  const firstQuery = query(collection(db,`Course`),where("idCurso", "==", studenInformation.courseId));
  const [value, loading, error] = useCollection(firstQuery);

  const updateData=(professor,courseName,institution)=>{
    setFinalMessage({...finalMessage, ['professor']: professor,['courseName']: courseName,['institution']: institution})
    //console.log(finalMessage)
  }
 
  const handelSelectGo=(idCourse) =>{
    setStudentInformation({...studenInformation,['courseId']:idCourse});
    evaluations();

  }


  if (loading) {
    return  <h2 className="text-2xl font-bold text-center mb-4"
    >Buscando ...</h2> ;
  }
  return (

    <div className="px-4 py-6">
      {loading && "Loading..."}
      {
        value.docs.length > 0 ? (
      value?.docs.map((doc) => (<div key={doc.id} className="px-4 py-6">
      <h2 className="font-bold mb-4">{doc.data().name} - {doc.data().userName} - [{doc.data().institution}] </h2>
      <p>
        Accede a las evaluaciones y muestra tu dedicación.
      </p>
      <p className="mt-4 text-gray-600">
        Al hacer clic en 'Entrar', aceptas la responsabilidad de mantener la integridad académica y evitar el plagio.
      </p>
      <p className="mt-4 text-gray-600">¡Estamos seguros de que aprovecharás al máximo este curso para crecer y alcanzar tus objetivos!</p>

      <div className="mt-6 flex justify-center space-x-4">
        <button 
         className="block w-1/2 py-3 px-4 bg-purple-500 text-white rounded-md shadow-md hover:bg-purple-600 focus:outline-none transition-colors duration-300"
         onClick={()=>{handelSelectGo(doc.id)
                       updateData(doc.data().userName,doc.data().name,doc.data().institution)}}>
          Entrar
        </button>
        <button 
        className="block w-1/2 py-3 px-4 bg-gray-300 text-gray-800 rounded-md shadow-md hover:bg-gray-400 focus:outline-none transition-colors duration-300"
        onClick={()=>{ navigator("/studentform")}}
        >
          Regresar
        </button>
      </div>

      </div>)) ):(
        <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative">
          <h1 className="font-bold mb-2">¡Ups! Código de curso incorrecto</h1>
          <p>Lo sentimos, el código del curso  <span className="italic">{studenInformation.courseId}</span>  que ingresaste no es válido.</p>
          <p>Por favor, haz clic en el botón 'Salir' en la parte superior derecha y vuelve a cargar el curso con el código correcto.</p>
          <p>Si necesitas ayuda, contáctate con tu profesor para obtener el código correcto. Estamos aquí para ayudarte.</p>         
        </div>)
      
    
    }

    </div>
  );
  
};

