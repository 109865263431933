import React, { useState } from 'react';
import {useStudent} from '../../context/studentsContext';
import { collection,serverTimestamp,where} from 'firebase/firestore';
import { db } from '../../firebase';
import { query, orderBy } from "firebase/firestore";
import {useCollection} from 'react-firebase-hooks/firestore'




export function EvaluationsList  ()  {
  const {evaluationsScreemMS, studenInformation,setStudentInformation, finalMessage, setFinalMessage } = useStudent();
  const timestampInSeconds = Math.round(Date.now() / 1000); 
  const firstQuery = query(collection(db,`Course`,studenInformation.courseId,'Evaluations'));
  const [value, loading, error] = useCollection(firstQuery);

  const activeEvaluation = value?.docs.filter((doc) => {
    const evaluationTimestamp = doc.data().timestamp.seconds;
    const evaluationTimeInMinutes = parseInt(doc.data().time) * 60;
    const expirationTimestamp = evaluationTimestamp + evaluationTimeInMinutes;
  
    return timestampInSeconds < expirationTimestamp;
  });
  
  const handelSelectEvaluatio= (idEvaluation) =>{
    setStudentInformation({...studenInformation,['idEvaluation']:idEvaluation});

//    console.log(value);
    evaluationsScreemMS()
//    console.log(finalMessage)
  }

  if (loading) {
    return  <h2 className="text-2xl font-bold text-center mb-4"
    >Buscando... </h2> ;
  }
  return (
 
    <div className="px-4 py-6">
      {loading && "Loading..."}
      {(activeEvaluation && activeEvaluation.length>0)  ? (
      <div>
        <h2 className="text-2xl font-bold text-center mb-4"
        onClick={()=>{console.log(activeEvaluation)}}>Evaluaciones disponibles:</h2>
        {activeEvaluation.map((doc) => (
          <div
           key={doc.id}
           className="px-4 py-6">
            <div key={doc.id} className="grid gap-4 grid-cols-1 sm:grid-cols-3">
              <button
                className="w-full h-24 bg-purple-500 text-white rounded-md shadow-md hover:bg-purple-600 focus:outline-none transition-colors duration-300"
                onClick={() => handelSelectEvaluatio(doc.id)}
              >
                {doc.data().name}
              </button>
            </div>
          </div>
        ))}
      </div>
    ) : (
      <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative">

        <h1  className="font-bold mb-2">¡Hola!</h1>
        <p>Parece que las evaluaciones aún no están activas en este momento.</p>
        <p>Es posible que el profesor esté a punto de enviar la prueba.</p>
        <p>Por favor, espera un momento o vuelve a ingresar más tarde.</p>
        <p>¡Gracias por tu comprensión y paciencia!</p>
      </div>
    )}
    </div>
  );
  
};


