import React from 'react';
import {useStudent} from '../../context/studentsContext';


export function Received() {
  const {finalMessage,studenInformation, sendMessage}=  useStudent();

  return (
    <div className="px-4 py-6">
    <div className="max-w-md mx-auto p-6 bg-purple-200 rounded-lg shadow-md">
        <h1 className="text-2xl font-semibold mb-4">¡Evaluación completada!</h1>
        <p className="mb-4">Tu respuesta ha sido recibida con éxito. ¡Muchas gracias por participar!</p>
        <p className="mb-4">Valoramos tu dedicación y esfuerzo en esta evaluación.</p>
    </div>
    </div>
  );
};
