import {Routes, Route} from 'react-router-dom';
import {Home} from './components/Home';

function App(){
  return(
    <div>
      Hola mMundo
    <Routes>
      <Route path ="/" elemente={<Home/>} >       
      </Route>
    </Routes>
    </div>


  )


}


export default App;