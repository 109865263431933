import {useState, React}  from 'react';
import { CameraIcon } from "@heroicons/react/24/solid";
import { useProfessor } from '../../context/professorContext';
import { useAuth} from "../../context/authContext";
import {CameraCapture} from "../professor/CameraCapture";

export function CreateEvaluation()  {
  const {getAnswer,evaluationInfo, setEvaluation} =useProfessor();
  const handleChange = ({target: {name,value}}) =>{
    setEvaluation({...evaluationInfo,[name]:value})
  };

  // Camera Options

  // Enviar Click
  const handleCreate = () =>{
    getAnswer(evaluationInfo);
  };

  return (
    <div className="px-4 py-4">
      <h2 className="text-2xl font-bold text-center mb-4">Creador de evaluación</h2>
      <div className="space-y-4">
        <div>
          <label htmlFor="name" className="block text-gray-700">Nombre</label>
          <input
            type="text"
            id="name"
            name="name"
            className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-purple-500 focus:border-purple-500"
            placeholder="Nombre de la evaluación"
            onChange={handleChange}
          />
        </div>
        <div>
          <label htmlFor="statement" className="block text-gray-700">Enunciado</label>
          <textarea
            id="statement"
            name="statement"
            rows="4"
            className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-purple-500 focus:border-purple-500"
            placeholder="Ingrese el enunciado de la evaluación"
            onChange={handleChange}
          ></textarea>
        </div>
        <div className="">
          <div className="space-x-2 items-center  md:content-around">
          
          {/* Camara Foto*/}
            <CameraCapture />
            </div>       
        </div>
        <div>
          <label htmlFor="time" className="block text-gray-700">Tiempo (minutos)</label>
          <select
            id="time"
            name="time"
            className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-purple-500 focus:border-purple-500"
            onChange={handleChange}>
            {[...Array(8)].map((_, index) => (
              <option key={index} value={(index + 1) * 5}>{(index + 1) * 5}</option>
            ))}
          </select>
        </div>
        <div>
        <label htmlFor="multipleChoice" className="block text-gray-700">Enunciado visible para los estudiantes?</label>
          <select
            id="visible"
            name="visible"
            className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-purple-500 focus:border-purple-500"
            onChange={handleChange}
          >
            <option value="yes">Sí</option>
            <option value="no">No</option>
          </select>

          <label htmlFor="multipleChoice" className="block text-gray-700">Opción múltiple (0-5)</label>
          <select
            id="multipleChoice"
            name="multipleChoice"
            className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-purple-500 focus:border-purple-500"
            onChange={handleChange}
          >
            {[...Array(6)].map((_, index) => (
              <option key={index} value={index}>{index}</option>
            ))}
          </select>
        </div>
        <div>
          <label htmlFor="openText" className="block text-gray-700">Texto abierto</label>
          <select
            id="openText"
            name="openText"
            className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-purple-500 focus:border-purple-500"
            onChange={handleChange}
          >
            <option value="yes">Sí</option>
            <option value="no">No</option>
          </select>
        </div>
        <button
          className="block w-full py-3 px-4 bg-purple-500 text-white rounded-md shadow-md hover:bg-purple-600 focus:outline-none transition-colors duration-300"
          onClick={() => handleCreate()}>

          Crear
        </button>
      </div>

    </div>
  );
};
