import logo from "./logo.svg"; // Ruta de tu archivo de imagen del logo
import { useNavigate } from "react-router-dom";

export function Landing() { 

    const navigator = useNavigate();
  
    return (
        <div className="bg-purple-600 text-white text-center h-screen flex flex-col justify-center items-center">
          <img
             className="w-21 h-20 mx-auto"
             src={logo}
            alt="Assistant Hellie Logo"
          />

          <h1 className="heading text-3xl font-bold mb-2">Assistant Hellie</h1>
          <p className="message text-2xl mb-2">
            ¡Bienvenido! Estamos construyendo una experiencia asombrosa para ti.
          </p>
          <p className="warning text-7xl mb-6 mt-6 text-yellow-400">⚠</p>
          <button
            className="button px-5 py-3 text-lg bg-purple-600 text-white border-2 border-white rounded-lg cursor-pointer transition-colors hover:bg-purple-700 hover:border-purple-700"
            onClick={()=>{navigator("/hellie-questions")}}
          >
            Ir a Hellie Questions
          </button>
          <a href="/messages" className="link text-white text-lg mt-5">
            Enviar mensajes
          </a>
          <p className="additional-info text-white text-lg mt-8">
            ¡No te pierdas nuestras increíbles herramientas! Utiliza Hellie
            Questions para evaluaciones rápidas o envíanos un
            mensaje para más información.
          </p>
          <div className="footer text-gray-600 text-xs mt-8">
            <p>COPYRIGHT 2023. ALL RIGHTS RESERVED. Design And Developed By Assistant Hellie</p>
          </div>
        </div>
      );
  }
    
  